import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { getValidAccessToken } from 'bootstrap/auth0/client';
import { store } from 'bootstrap/redux';

let storedAxiosInstance: AxiosInstance = null


export const axiosInstance = storedAxiosInstance 
  ? storedAxiosInstance 
  : (() => {
    storedAxiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 15000
    })
    storedAxiosInstance.interceptors.request.use(async (config) => {
      const validToken = getValidAccessToken()
      if (!validToken) {
        window.location.href = '/login'
        Promise.reject('Token is no longer valid.')
      }
      config.headers.Authorization = `bearer ${validToken}`
      return config

    })
    return storedAxiosInstance
  })();

export const axiosBaseQuery = (): BaseQueryFn<{
  url: string,
  method: AxiosRequestConfig['method'],
  data?: AxiosRequestConfig['data'],
}> => async ({ url, method, data }) => {
  try {
    const result = await axiosInstance.request({ 
      url: `${process.env.REACT_APP_API_URL}/${url}`, 
      method, 
      data
    })
    return { data: result.data }
  } catch (axiosError) {
    const err = axiosError as AxiosError
    return { error: { status: err.response?.status, data: err.response?.data }}
  }
}