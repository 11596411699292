import { ReportedComment } from 'features/comments/types/commentTypes';
import { Meta } from 'shared/types/apiTypes';
import { mainService } from '..';


const commentServices = mainService.injectEndpoints({
  endpoints: (build) => ({
    getReportedComments: build.query<{ data: Array<ReportedComment>, meta: Meta}, number>({
      query: (pageNumber) => ({ url: `admin/reports/comments?page=${pageNumber}&count=10`, method: 'GET' })
    }),
    removeReportedComment: build.mutation<void, {postId: number, commentId: number, pageNumber: number}>({
      query: ({ commentId, postId }) => ({
        url: `admin/posts/${postId}/comments/${commentId}/remove`,
        method: 'PATCH'
      }),
      async onQueryStarted({ commentId, pageNumber }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          const patchResult = dispatch(
            commentServices.util.updateQueryData('getReportedComments', pageNumber, (draft) => {
              const comment =  draft.data.find((comment) => comment.id === commentId)
              comment.status = 'removed'
            })
          )
        } catch (error) { console.log({ error }) }
      }
    }),
    restoreReportedComment: build.mutation<void, {postId: number, commentId: number, pageNumber: number }>({
      query: ({ commentId, postId }) => ({
        url: `admin/posts/${postId}/comments/${commentId}/restore`,
        method: 'PATCH'
      }),
      async onQueryStarted({ commentId, pageNumber }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          const patchResult = dispatch(commentServices.util.updateQueryData('getReportedComments', pageNumber, (draft) => {
            const comment =  draft.data.find((comment) => comment.id === commentId)
            if (comment.status === 'reported') {
              draft.data = draft.data.filter((comment) => comment.id !== commentId)
            } else comment.status = 'reported'
          }))
        } catch (error) { console.log({ error }) }
      }
    }),
    deleteReportedComment: build.mutation<void, { postId: number, commentId: number, pageNumber: number}>({
      query: ({ postId, commentId }) => ({
        url: `admin/posts/${postId}/comments/${commentId}`,
        method: "DELETE"
      }),
      async onQueryStarted({ commentId, postId, pageNumber }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          const patchResult = dispatch(commentServices.util.updateQueryData('getReportedComments', pageNumber, (draft) => {
            draft.data = draft.data.filter((comment) => comment.id !== postId)
          }))
        } catch (error) { console.log({ error }) }
      }
    })
  })
})

export const { useGetReportedCommentsQuery, useDeleteReportedCommentMutation, useRemoveReportedCommentMutation, useRestoreReportedCommentMutation } = commentServices