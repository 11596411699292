import { Box, Link, HStack, Heading, Stack, Text } from '@chakra-ui/react'
import { Outlet, useLocation } from 'react-router-dom'
import { Link as routerLink } from 'react-router-dom'
import colors from 'shared/constants/colors'

const Layout = () => {
  let location = useLocation();
  return (
    <>
      <Stack spacing='8px' shadow="md" borderBottom="InactiveBorder" backgroundColor={colors.green} padding='8px 16px'>
        <img width={200} height={200} src={'LeaderboardLogoWhite.png'} />
        <HStack>
          <Link color={ location.pathname === '/posts' ? 'white' : 'black'} to='/posts' as={routerLink}>Posts</Link>
          <Link color={ location.pathname === '/comments' ? 'white' : 'black'} to='/comments' as={routerLink}>Comments</Link>
          <Link color={ location.pathname === '/profiles' ? 'white' : 'black'} to='/profiles' as={routerLink}>Profiles</Link>
        </HStack>
      </Stack>
      <Box as='main' height='full'>
        <Box margin='auto' padding="16px" maxWidth={1440}>
          <Text paddingBottom={'16px'} textTransform='capitalize' fontSize='4xl' >{location.pathname.slice(1)}</Text>
          <Outlet />
        </Box>
      </Box>
    </>
  )
}

export default Layout