import { Td, Tr } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import colors from 'shared/constants/colors'
import commentSlice from '../redux/commentSlice'
import { ReportedComment } from '../types/commentTypes'

type CommentRowProps = {
  comment: ReportedComment
}

const CommentRow = ({ comment }: CommentRowProps) => {
  const dispatch = useDispatch()

  const handleRowClick = () => {
    dispatch(commentSlice.actions.setActiveCommentId(comment.id))
  }

  return (
    <Tr tabIndex={0} role='button' onClick={handleRowClick} _hover={{ backgroundColor: colors.green10, cursor: 'pointer' }}>
      <Td>{comment.id}</Td>
      <Td>{dayjs(comment.createdAt).format("YYYY/MM/DD hh:mm A")}</Td>
      <Td>{comment.content}</Td>
      <Td textTransform="capitalize" color={comment.status === 'removed' ? 'red' : 'black'}>{comment.status}</Td>
    </Tr>
  )
}

export default CommentRow