import { Button, Box, Text, Divider, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Stack, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetReportedCommentsQuery, useRemoveReportedCommentMutation, useRestoreReportedCommentMutation, useDeleteReportedCommentMutation } from 'shared/api/services/commentServices'
import usePagination from 'shared/hooks/usePagination'
import { selectActiveCommentId } from '../redux/commentSelectors'
import commentSlice from '../redux/commentSlice'

const CommentActions = () => {
  const activeQuery = usePagination('page')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const dispatch = useDispatch()
  const activeCommentId = useSelector(selectActiveCommentId)
  const comment = useGetReportedCommentsQuery(+activeQuery || 1, {
    selectFromResult: result => {
      const comment = result.data.data.find((comment) => comment.id === activeCommentId )
      return comment
    }
  })

  const [removeComment, { isLoading: removeIsLoading }] = useRemoveReportedCommentMutation()
  const [deleteComment, { isLoading: deleteIsLoading }] = useDeleteReportedCommentMutation()
  const [restoreComment, { isLoading: restoreIsLoading }] = useRestoreReportedCommentMutation()
  const mutationInProgress = removeIsLoading || deleteIsLoading || restoreIsLoading 

  const handleClose = () => {
    dispatch(commentSlice.actions.setActiveCommentId(null))
  }

  const handleRestore = () => { 
    if (comment.status === 'reported') {
      // this will remove reported status
      onClose()
      handleClose()
    }
    restoreComment({ pageNumber: +activeQuery || 1, commentId: activeCommentId, postId: comment.post.id })
  }

  const handleRemove = () => { 
    removeComment({ pageNumber: +activeQuery || 1, commentId: activeCommentId, postId:  comment.post.id })
  }

  const handleDelete = () => { 
    onClose()
    handleClose()
    deleteComment({ pageNumber: +activeQuery || 1, commentId: activeCommentId, postId: comment.post.id })
  }
  

  return (
    <>
      <Drawer placement='right' onClose={handleClose} isOpen={activeCommentId !== null}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth='1px'>Comment Actions</DrawerHeader>
          <DrawerBody>
            <Stack spacing={'8px'} divider={<Divider />}>
              <Box width='full'>
                <Button  
                  disabled={mutationInProgress} 
                  colorScheme='green' 
                  width='full'
                  onClick={handleRestore}
                >
                  Restore
                </Button>
                <Text marginTop='8px' fontSize='sm'>Restores comment that has been soft deleted</Text>
              </Box>
              <Box width='full'>
                <Button 
                  onClick={handleRemove} 
                  disabled={mutationInProgress || comment?.status === 'removed'} 
                  colorScheme='red' 
                  width='full'
                >
                Remove
                </Button>
                <Text marginTop='8px' fontSize='sm'>Soft deletes a comment</Text>
              </Box>
              <Box width='full'>
                <Button onClick={onOpen} disabled={mutationInProgress} colorScheme='red' width='full'>Delete</Button>
                <Text marginTop='8px' fontSize='sm'>Deletes a comment completely</Text>
              </Box>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Comment
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default CommentActions