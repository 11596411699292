import { Auth0DecodedHash } from 'auth0-js'
import { checkIfTokenIsExpired, client, getValidAccessToken } from 'bootstrap/auth0/client'
import { selectAccessToken } from 'features/authorization/redux/authorizationSelectors'
import authorizationSlice from 'features/authorization/redux/authorizationSlice'
import { DecodedAccessToken } from 'features/authorization/types/tokenTypes'
import jwtDecode from 'jwt-decode'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** Returns true/false if the component has access to access token. */
const useAuth = (): { isInvalid: boolean, isValidated: boolean, isLoading: boolean} => {
  const [isLoading, setIsLoading] = useState(true)
  const [isValidated, setIsValidated] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const dispatch = useDispatch()
  const accessToken = useSelector(selectAccessToken)


  const setTokenInvalid = () => {
    setIsInvalid(true)
    setIsLoading(false)
  }

  const setTokenValid = (accessToken: string) => {
    setIsValidated(true)
    setIsLoading(false)
    window.sessionStorage.setItem('leaderboardAccess', accessToken)
  }

  const handleHashParse = useCallback((err, results: Auth0DecodedHash) => {
    if (err) { console.log(err); setTokenInvalid() }
    else if (results) {
      dispatch(authorizationSlice.actions.setToken({
        access: results.accessToken
      }))
      setTokenValid(results.accessToken)
    }
    else {
      // Unknown state
      setTokenInvalid()
    }
  }, [dispatch])

  // Check if hash for token is present at / path. (AuthorizePage)
  // Otherwise attempt to use redux -> session storage
  useEffect(() => {
    const accessToken = getValidAccessToken()
    if (accessToken) { setTokenValid(accessToken)}
    else if (window.location.pathname === "/") { 
      client.parseHash({ hash: window.location.hash}, handleHashParse) 
    }
    else {
      setTokenInvalid()
    }
  }, [accessToken, dispatch, handleHashParse])

  return {
    isLoading,
    isValidated,
    isInvalid
  }
}

export default useAuth