import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ProfileInitialState = {
  activeProfileId: number
}

const INITIAL_STATE: ProfileInitialState = {
  activeProfileId: null
}

const profileSlice = createSlice({
  name: 'profiles',
  initialState: INITIAL_STATE,
  reducers: {
    setProfileId(state, action: PayloadAction<number>) {
      state.activeProfileId = action.payload
    }
  }
})

export default profileSlice