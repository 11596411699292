import auth0 from 'auth0-js'
import { store } from 'bootstrap/redux'
import jwtDecode from 'jwt-decode'
import { DecodedAccessToken } from 'features/authorization/types/tokenTypes'
import dayjs from 'dayjs'

export const client = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH_0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH_0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH_0_AUDIENCE,
  redirectUri: window.location.origin,
  responseType: 'token id_token'
})

/**
 * attempts to get valid token from redux first and then session storage and returning null if neither is valid.
 */
export const getValidAccessToken = (): string => {
  const reduxToken = store.getState().auth.accessToken
  if (reduxToken) {
    const decodedToken: DecodedAccessToken = jwtDecode(reduxToken)
    if (checkIfTokenIsExpired(decodedToken.exp)) {
      // if the token is expired we return null
      return null
    }
    return reduxToken
  } else {
    // no redux token set... Attempt storage
    const sessionToken = window.sessionStorage.getItem('leaderboardAccess')
    if (sessionToken) {
      const decodedToken: DecodedAccessToken = jwtDecode(sessionToken)
      if (checkIfTokenIsExpired(decodedToken.exp)) {
        return null
      }
      return sessionToken
    }
    return null
  }
}

export const checkIfTokenIsExpired = (tokenExpiration: number): boolean => {
  // if current date is after the token's expiration then the token is expired.
  return dayjs().isAfter(dayjs.unix(tokenExpiration))
}
