import { ReportedProfile } from 'features/profiles/types/profileTypes';
import { Meta } from 'shared/types/apiTypes';
import { mainService } from '..';


const profileServices = mainService.injectEndpoints({
  endpoints: (build) => ({
    getReportedProfiles: build.query<{ data: Array<ReportedProfile>, meta: Meta}, number>({
      query: (pageNumber) => ({ url: `admin/reports/users?page=${pageNumber}&count=10`, method: 'GET' })
    }),
    removeReportedProfile: build.mutation<void, {userId: number, pageNumber: number}>({
      query: ({ userId }) => ({
        url: `admin/users/${userId}/remove`,
        method: 'PATCH'
      }),
      async onQueryStarted({ userId, pageNumber }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          const patchResult = dispatch(
            profileServices.util.updateQueryData('getReportedProfiles', pageNumber , (draft) => {
              const profile =  draft.data.find((profile) => profile.id === userId)
              profile.activeStatus = 'blocked'
            })
          )
        } catch (error) { console.log({ error }) }
      }
    }),
    restoreReportedProfile: build.mutation<void, {userId: number, pageNumber: number }>({
      query: ({ userId }) => ({
        url: `admin/users/${userId}/restore`,
        method: 'PATCH'
      }),
      async onQueryStarted({ userId, pageNumber }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          const patchResult = dispatch(profileServices.util.updateQueryData('getReportedProfiles', pageNumber, (draft) => {
            draft.data = draft.data.filter((profile) => profile.id !== userId)
          }))
        } catch (error) { console.log({ error }) }
      }
    })
  })
})

export const { useGetReportedProfilesQuery, useRemoveReportedProfileMutation, useRestoreReportedProfileMutation } = profileServices