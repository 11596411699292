import { Button, Box, Text, Divider, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Stack, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetReportedCommentsQuery, useRemoveReportedCommentMutation, useRestoreReportedCommentMutation, useDeleteReportedCommentMutation } from 'shared/api/services/commentServices'
import { useGetReportedProfilesQuery, useRemoveReportedProfileMutation, useRestoreReportedProfileMutation } from 'shared/api/services/profileServices'
import usePagination from 'shared/hooks/usePagination'
import { selectActiveProfileId } from '../redux/profileSelectors'
import profileSlice from '../redux/profileSlice'

const ProfileActions = () => {
  const activeQuery = usePagination('page')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const dispatch = useDispatch()
  const activeProfileId = useSelector(selectActiveProfileId)
  const profile = useGetReportedProfilesQuery(+activeQuery || 1, {
    selectFromResult: result => {
      const profile = result.data.data.find((profile) => profile.id === activeProfileId )
      return profile
    }
  })

  const [removeProfile, { isLoading: removeIsLoading }] = useRemoveReportedProfileMutation()
  const [restoreProfile, { isLoading: restoreIsLoading }] = useRestoreReportedProfileMutation()
  const mutationInProgress = removeIsLoading || restoreIsLoading 

  const handleRestore = () => { 
    restoreProfile({ pageNumber: +activeQuery || 1, userId: profile.id })
    handleClose()
  }

  const handleRemove = () => { 
    removeProfile({ pageNumber: +activeQuery || 1, userId: profile.id })
  }


  const handleClose = () => {
    dispatch(profileSlice.actions.setProfileId(null))
  }


  return (
    <>
      <Drawer placement='right' onClose={handleClose} isOpen={activeProfileId !== null}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth='1px'>Profile Actions</DrawerHeader>
          <DrawerBody>
            <Stack spacing={'8px'} divider={<Divider />}>
              <Box width='full'>
                <Button  
                  disabled={mutationInProgress} 
                  colorScheme='green' 
                  width='full'
                  onClick={handleRestore}
                >
                  Restore
                </Button>
                <Text marginTop='8px' fontSize='sm'>Restores profile that has been soft deleted or reported</Text>
              </Box>
              <Box width='full'>
                <Button 
                  onClick={handleRemove}
                  disabled={mutationInProgress || profile.activeStatus === 'blocked'} 
                  colorScheme='red' 
                  width='full'
                >
                Remove
                </Button>
                <Text marginTop='8px' fontSize='sm'>Soft deletes a profile</Text>
              </Box>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Comment
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={null} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default ProfileActions