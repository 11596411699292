import { ReportedPost } from 'features/posts/types/postTypes';
import { Meta } from 'shared/types/apiTypes';
import { mainService } from '..';


const postServices = mainService.injectEndpoints({
  endpoints: (build) => ({
    getReportedPosts: build.query<{ data: Array<ReportedPost>, meta: Meta }, number>({
      query: (pageNumber) => ({ url: `admin/reports/posts?page=${pageNumber}&count=10`, method: 'GET' })
    }),
    removeReportedPosts: build.mutation<void, {postId: number, pageNumber: number}>({
      query: ({ postId }) => ({
        url: `admin/posts/${postId}/remove`,
        method: 'PATCH'
      }),
      async onQueryStarted({ postId, pageNumber }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          const patchResult = dispatch(
            postServices.util.updateQueryData('getReportedPosts', pageNumber, (draft) => {
              const post =  draft.data.find((post) => post.id === postId)
              post.status = 'removed'
            })
          )
        } catch (error) { console.log({ error }) }
      }
    }),
    restoreReportedPosts: build.mutation<void, { postId: number, pageNumber: number }>({
      query: ({ postId }) => ({
        url: `admin/posts/${postId}/restore`,
        method: 'PATCH'
      }),
      async onQueryStarted({ postId, pageNumber }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          const patchResult = dispatch(postServices.util.updateQueryData('getReportedPosts', pageNumber, (draft) => {
            const post =  draft.data.find((post) => post.id === postId)
            if (post.status === 'reported') {
              draft.data = draft.data.filter((post) => post.id !== postId)
            } else post.status = 'reported'
          }))
        } catch (error) { console.log({ error }) }
      }
    }),
    deleteReportedPosts: build.mutation<void, { postId: number, pageNumber: number }>({
      query: ({ postId, pageNumber }) => ({
        url: `admin/posts/${postId}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ postId, pageNumber }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          const patchResult = dispatch(postServices.util.updateQueryData('getReportedPosts', pageNumber, (draft) => {
            draft.data = draft.data.filter((post) => post.id !== postId)
          }))
        } catch (error) { console.log({ error }) }
      }
    })
  })
})

export const { useGetReportedPostsQuery, useDeleteReportedPostsMutation, useRestoreReportedPostsMutation, useRemoveReportedPostsMutation } = postServices