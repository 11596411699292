import PostImageModal from 'features/posts/components/PostImageModal'
import PostTable from 'features/posts/components/PostTable'
import Pagination from 'shared/components/Pagination'

const Posts = () => {
  return (
    <>
      <PostTable />
      <PostImageModal />
    </>
  )
}

export default Posts