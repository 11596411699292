import LoginForm from 'features/authorization/components/LoginForm'
import { Center } from '@chakra-ui/react'
import styles from './Login.module.scss'
import colors from 'shared/constants/colors'

const Login = () => {
  return (
    <Center backgroundColor={colors.white} height='full'>
      <LoginForm />
    </Center>
  )
}

export default Login