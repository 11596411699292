import { useState } from 'react'
import { Input, Stack, Button, Heading } from '@chakra-ui/react'
import { client } from 'bootstrap/auth0/client'

const LoginForm = () => {
  const [needsCode, setNeedsCode] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [code, setCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handlePasswordStart = (error) => {
    if (error) { console.log(error) }
    else { setNeedsCode(true) }
    setIsLoading(false)
  }

  const handleVerify = (error) => {
    if (error) {
      console.log(error)
      setIsLoading(false)
      setNeedsCode(false)
    }
  }

  const handleSubmit = () => {
    if (needsCode) {
      setIsLoading(true)
      client.passwordlessLogin({
        verificationCode: code,
        connection: 'sms',
        phoneNumber: `+1${phoneNumber}`
      }, handleVerify )
    }
    else {
      setIsLoading(true)
      client.passwordlessStart({ 
        connection: 'sms',
        send: 'code',
        phoneNumber: `+1${phoneNumber}`
      }, handlePasswordStart )
    }
  }

  const handleChange = (event) => { 
    if (needsCode) { setCode(event.target.value) }
    else { setPhoneNumber(event.target.value) }
  }

  const handleKey = (event) => {
    if ((event.key as string).toLowerCase() === 'enter') {
      handleSubmit()
    }
  }


  return (
    <Stack backgroundColor={'white'} spacing='6' borderWidth='2px' borderRadius='lg' align='center' shadow='lg' p={"16px 24px 24px"} w='md'>
      <img src={'LeaderboardLogoTransparent.png'} />
      <Input 
        value={needsCode ? code : phoneNumber} 
        onChange={handleChange} 
        type={needsCode ? 'text' : 'tel'} 
        placeholder={needsCode ? "Code": "Phone number"} 
        size='md' 
        onKeyDown={handleKey}
      />
      <Button  disabled={isLoading} onClick={handleSubmit} colorScheme="green" width="full">Submit</Button>
    </Stack>
  )
}

export default LoginForm