import { Button, Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { useGetReportedPostsQuery } from 'shared/api/services/postServices'
import usePagination from 'shared/hooks/usePagination'
import PostActions from './PostActions'
import PostRow from './PostRow'
import Pagination from 'shared/components/Pagination'

const PostTable = () => {
  const activeQuery = usePagination('page')
  const { data: { data, meta } = {}, isLoading, isError } = useGetReportedPostsQuery(+activeQuery || 1, { pollingInterval: 600000 })
  if (isError || isLoading) return null
  return (
    <>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th minWidth={105}>Game ID</Th>
            <Th>Created Date</Th>
            <Th>Description</Th>
            <Th>Images</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {  data.map((post) => <PostRow key={post.id} post={post} />) }
        </Tbody>
      </Table>
      <PostActions />
      <Pagination meta={meta} />
    </>
  )

}

export default PostTable