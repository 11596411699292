import { Button, Box, Text, Divider, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Stack, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDeleteReportedPostsMutation, useGetReportedPostsQuery, useRemoveReportedPostsMutation, useRestoreReportedPostsMutation } from 'shared/api/services/postServices'
import usePagination from 'shared/hooks/usePagination'
import { selectActivePostId } from '../redux/postsSelectors'
import postSlice from '../redux/postsSlice'

const PostActions = () => {
  const activeQuery = usePagination('page')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const dispatch = useDispatch()
  const activePostId = useSelector(selectActivePostId)
  const post = useGetReportedPostsQuery(+activeQuery || 1, {
    selectFromResult: result => {
      const post = result.data.data.find((post) => post.id === activePostId )
      return post
    }
  })

  const [removePost, { isLoading: removeIsLoading }] = useRemoveReportedPostsMutation()
  const [deletePost, { isLoading: deleteIsLoading }] = useDeleteReportedPostsMutation()
  const [restorePost, { isLoading: restoreIsLoading }] = useRestoreReportedPostsMutation()
  const mutationInProgress = removeIsLoading || deleteIsLoading || restoreIsLoading 

  const handleClose = () => {
    dispatch(postSlice.actions.setActivePostId(null))
  }

  const handleRestore = () => { 
    if (post.status === 'reported') {
      // this will remove reported status
      onClose()
      handleClose()
    }
    restorePost({ pageNumber: +activeQuery || 1, postId: activePostId })
  }

  const handleRemove = () => { removePost({ pageNumber: +activeQuery || 1, postId: activePostId })}
  const handleDelete = () => { 
    onClose()
    handleClose()
    deletePost({ pageNumber: +activeQuery || 1, postId: activePostId }) 
  }
  

  return (
    <>
      <Drawer placement='right' onClose={handleClose} isOpen={activePostId !== null}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth='1px'>Post Actions</DrawerHeader>
          <DrawerBody>
            <Stack spacing={'8px'} divider={<Divider />}>
              <Box width='full'>
                <Button  
                  disabled={mutationInProgress} 
                  colorScheme='green' 
                  width='full'
                  onClick={handleRestore}
                >
                  Restore
                </Button>
                <Text marginTop='8px' fontSize='sm'>Restores post that has been soft deleted</Text>
              </Box>
              <Box width='full'>
                <Button 
                  onClick={handleRemove} 
                  disabled={mutationInProgress || post?.status === 'removed'} 
                  colorScheme='red' 
                  width='full'
                >
                Remove
                </Button>
                <Text marginTop='8px' fontSize='sm'>Soft deletes a post</Text>
              </Box>
              <Box width='full'>
                <Button onClick={onOpen} disabled={mutationInProgress} colorScheme='red' width='full'>Delete</Button>
                <Text marginTop='8px' fontSize='sm'>Deletes a post completely</Text>
              </Box>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Post
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default PostActions