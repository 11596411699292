import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import usePagination from 'shared/hooks/usePagination'
import Pagination from 'shared/components/Pagination'
import { useGetReportedProfilesQuery } from 'shared/api/services/profileServices'
import ProfileRow from './ProfileRow'
import ProfileActions from './ProfileActions'


const ProfileTable = () => {
  const activeQuery = usePagination('page')
  const { data: { data, meta } = {}, isLoading, isError } = useGetReportedProfilesQuery(+activeQuery || 1, { pollingInterval: 600000 })
  console.log(data)

  if (isError || isLoading) return null
  return (
    <>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>Avatar</Th>
            <Th>ID</Th>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Player Name</Th>
            <Th>Bio</Th>
          </Tr>
        </Thead>
        <Tbody> 
          { data.map((profile) => <ProfileRow key={profile.id} profile={profile} />) }
        </Tbody>
      </Table>
      <ProfileActions />
      <Pagination meta={meta} />
    </>
  )
}

export default ProfileTable