import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import usePagination from 'shared/hooks/usePagination'
import CommentActions from './CommentActions'
import CommentRow from './CommentRow'
import Pagination from 'shared/components/Pagination'
import { useGetReportedCommentsQuery } from 'shared/api/services/commentServices'

const CommentTable = () => {
  const activeQuery = usePagination('page')
  const { data: { data, meta } = {}, isLoading, isError } = useGetReportedCommentsQuery(+activeQuery || 1, { pollingInterval: 600000 })
  if (isError || isLoading) return null
  return (
    <>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Created Date</Th>
            <Th>Content</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody> 
          { data.map((comment) => <CommentRow key={comment.id} comment={comment} />) }
        </Tbody>
      </Table>
      <CommentActions />
      <Pagination meta={meta} />
    </>
  )
}

export default CommentTable