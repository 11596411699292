import { Center, CircularProgress, Text } from '@chakra-ui/react'
import { client } from 'bootstrap/auth0/client'
import authorizationSlice from 'features/authorization/redux/authorizationSlice'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import useAuth from 'shared/hooks/useAuth'


const Authorize = () => {
  useAuth()
  return (
    <Navigate to="/posts" replace />
  )
}

export default Authorize