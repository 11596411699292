import { configureStore, combineReducers, Action } from '@reduxjs/toolkit'
import authorizationSlice from 'features/authorization/redux/authorizationSlice'
import commentSlice from 'features/comments/redux/commentSlice'
import postSlice from 'features/posts/redux/postsSlice'
import profileSlice from 'features/profiles/redux/profileSlice'
import { mainService } from 'shared/api'

const combinedReducer = combineReducers({
  [authorizationSlice.name]: authorizationSlice.reducer,
  [postSlice.name]: postSlice.reducer,
  [commentSlice.name]: commentSlice.reducer,
  [profileSlice.name]: profileSlice.reducer,
  [mainService.reducerPath]: mainService.reducer
})

export type RootState = ReturnType<typeof combinedReducer>

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(mainService.middleware)
})

export type AppDispatch = typeof store.dispatch