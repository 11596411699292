import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import ProtectedRoute from './shared/components/ProtectedRoute';
import Layout from './pages/Layout';
import Login from './pages/Login';
import { Provider } from 'react-redux'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { store } from 'bootstrap/redux';
import Posts from 'pages/Posts';
import Authorize from 'pages/Authorize';
import Comments from 'pages/Comments';
import Profiles from 'pages/Profiles';


function App() {
  return (
    <Provider store={store}>
      <ChakraProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={
                <ProtectedRoute>
                  <Authorize />
                </ProtectedRoute>
              }  />
              <Route path='/posts' element={
                <ProtectedRoute>
                  <Posts />
                </ProtectedRoute>
              } />
              <Route path='/comments' element={
                <ProtectedRoute>
                  <Comments />
                </ProtectedRoute>
              } />
              <Route path='/profiles' element={
                <ProtectedRoute>
                  <Profiles />
                </ProtectedRoute>
              } />
            </Route>
            <Route path='/login' element={<Login />} />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
