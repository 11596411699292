import { Modal, ModalOverlay, ModalContent, Text, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, AspectRatio, Image } from '@chakra-ui/react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectActiveImages } from '../redux/postsSelectors'
import postSlice from '../redux/postsSlice'

const PostImageModal = () => {
  const [imageIndex, setImageIndex] = useState(0)
  const dispatch = useDispatch()
  const activeImages = useSelector(selectActiveImages)

  const handleClose = () => {
    dispatch(postSlice.actions.setActiveImages([]))
    setImageIndex(0)
  }

  const handlePrevious = () => {
    setImageIndex((prevState) => prevState - 1)
  }

  const handleNext = () => {
    setImageIndex((prevState) => prevState + 1)
  }


  return (
    <Modal size="lg" onClose={handleClose} isCentered isOpen={Boolean(activeImages.length)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Photos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Image src={activeImages[imageIndex]?.url} alt='Reported Image' objectFit='contain' />
        </ModalBody>
        <ModalFooter justifyContent="space-between" >
          <Button onClick={handlePrevious} disabled={imageIndex === 0}>Previous</Button>
          <Text fontStyle='italic'>{`Image ${imageIndex + 1} of ${activeImages.length}`}</Text>
          <Button onClick={handleNext} disabled={imageIndex === activeImages?.length - 1}>Next</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PostImageModal