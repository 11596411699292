const colors = {
  transparent: 'rgba(0,0,0,0)',
  translucent: 'rgba(255,255,255,0.01)',
  translucentGreen: 'rgba(0, 194, 139, 0.8)',
  translucentGreen60: 'rgba(0, 81, 87, 0.8)',
  /* ---- */
  white70: '#FCFDFD',
  white60: '#F6F8F9',
  white: '#FFFFFF',
  /* ---- */
  black60: '#121618',
  black: '#222B2E',
  gray40: '#5F6A6A',
  gray30: '#8F9696',
  gray20: '#C5CACC',
  gray10: '#EDEFEF',
  /* ---- */
  green70: '#00523B',
  green60: '#009067',
  green: '#00CD93',
  green40: '#40DAAE',
  green30: '#80E6C9',
  green20: '#BFF3E4',
  green10: '#E5FAF4',
  /* ---- */
  purple70: '#2E295B',
  purple60: '#463E88',
  purple: '#5D53B6',
  purple40: '#857EC8',
  purple30: '#AEA9DB',
  purple20: '#D7D4ED',
  purple10: '#EFEEF8',
  /* ---- */
  yellow70: '#785E02',
  yellow60: '#BC9404',
  yellow: '#FBC506',
  yellow40: '#FCD444',
  yellow30: '#FDE282',
  yellow20: '#FEF1C1',
  yellow10: '#FFF9E6',
  /* ---- */
  red70: '#5F2020',
  red60: '#A73838',
  red: '#EE5050',
  red40: '#F27C7C',
  red30: '#F7A7A7',
  red20: '#FBD3D3',
  red10: '#FCEDEE',
  /* --- */
  teal: '#008790',
  teal40: '#01A891'
}

export default colors
