import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthInitialState = {
  accessToken: string,
}

const INITIAL_STATE: AuthInitialState = {
  accessToken: ""
}

const authorizationSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setToken(state, action: PayloadAction<{ access: string }>) {
      state.accessToken = action.payload.access
    }
  }
})

export default authorizationSlice