import { Box, Flex } from '@chakra-ui/react'
import ReactPaginate from 'react-paginate'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { Meta } from 'shared/types/apiTypes'
import styles from './Pagination.module.scss'

type PaginationProps = {
  meta: Meta,
}

const Pagination = ({ meta }: PaginationProps) => {
  const navigate = useNavigate()

  const handlePageChange = ({ selected }: { selected: number }) => {
    navigate({
      'pathname': '',
      'search': `?${createSearchParams({page: (selected + 1).toString()})}`
    })
  }


  return (
    <Flex marginTop={16} justifyContent='flex-end'>
      <ReactPaginate 
        pageCount={meta.total_pages}
        onPageChange={handlePageChange}
        className={styles.pagination}
        activeClassName={styles.active}
      />
    </Flex>
  )
}

export default Pagination