import { Center, CircularProgress } from '@chakra-ui/react'
import { Fragment, ReactNode } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

type ProtectedRouteProps = {
  children: ReactNode
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isInvalid, isLoading, isValidated } = useAuth()
  if (isInvalid) {
    return (
      <Navigate to="/login" replace />
    )
  }
  if (isLoading) {
    return (
      <Center>
        <CircularProgress isIndeterminate />
      </Center>
    )
  }
  if (isValidated) { 
    return (
      <Fragment>
        {children}
      </Fragment>
    )
  }
}

export default ProtectedRoute