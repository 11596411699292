import { Td, Tr, Image, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import colors from 'shared/constants/colors'
import profileSlice from '../redux/profileSlice'
import { ReportedProfile } from '../types/profileTypes'

type ProfileRowProps = {
  profile: ReportedProfile
}

const ProfileRow = ({ profile  }: ProfileRowProps) => {
  const dispatch = useDispatch()

  const handleRowClick = () => {
    dispatch(profileSlice.actions.setProfileId(profile.id))
  }

  return (
    <Tr tabIndex={0} role='button' onClick={handleRowClick} _hover={{ backgroundColor: colors.green10, cursor: 'pointer' }}>
      <Td>
        {
          profile.avatarUrl 
            ? <Image objectFit='cover' boxSize={50} borderRadius={'50%'} src={profile.avatarUrl} alt={profile.playerName} />
            : <Text>N/A</Text>
        }
      </Td>
      <Td>{profile.id}</Td>
      <Td>{profile.firstName}</Td>
      <Td>{profile.lastName}</Td>
      <Td>{profile.playerName}</Td>
      <Td opacity={!profile?.bio?.length ? 0.5 : 1}  maxW={600}>{profile.bio ? profile.bio : 'No Bio'}</Td>
    </Tr>
  )
}

export default ProfileRow