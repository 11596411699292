import { Td, Tr, Button, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import colors from 'shared/constants/colors'
import postSlice from '../redux/postsSlice'
import { ReportedPost } from '../types/postTypes'

type PostRowProps = {
  post: ReportedPost
}

const PostRow = ({ post }: PostRowProps) => {
  console.log({ post })
  const dispatch = useDispatch()

  const handleRowClick = () => {
    dispatch(postSlice.actions.setActivePostId(post.id))
  }

  const handleButtonClick = (e) => {
    e.stopPropagation()
    dispatch(postSlice.actions.setActiveImages(post.photos))
  }

  return (
    <Tr tabIndex={0} role='button' onClick={handleRowClick} _hover={{ backgroundColor: colors.green10, cursor: 'pointer' }}>
      <Td>{post.id}</Td>
      <Td>{post.gameId}</Td>
      <Td>{dayjs(post.createdAt).format("YYYY/MM/DD hh:mm A")}</Td>
      <Td fontStyle={!post.description ? 'italic' : null}>{post.description || "No description provided."}</Td>
      <Td>
        {
          !post.photos.length 
            ? <Text fontStyle='italic'>No photo provided.</Text>
            : <Button disabled={!post.photos.length} onClick={handleButtonClick} colorScheme="blue" variant='link'>View</Button>
        }
      </Td>
      <Td textTransform="capitalize" color={post.status === 'removed' ? 'red' : 'black'}>{post.status}</Td>
    </Tr>
  )
}

export default PostRow