import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportedPost } from '../types/postTypes';

type AuthInitialState = {
  activePostId: number
  activeImages: ReportedPost['photos']
}

const INITIAL_STATE: AuthInitialState = {
  activePostId: null,
  activeImages: []
}

const postSlice = createSlice({
  name: 'posts',
  initialState: INITIAL_STATE,
  reducers: {
    setActivePostId(state, action: PayloadAction<number>) {
      state.activePostId = action.payload
    },
    setActiveImages(state, action: PayloadAction<ReportedPost['photos']>) {
      if (action.payload === null) { state.activeImages = [] }
      else state.activeImages = action.payload
    }
  }
})

export default postSlice