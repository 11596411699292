import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthInitialState = {
  activeCommentId: number
}

const INITIAL_STATE: AuthInitialState = {
  activeCommentId: null
}

const commentSlice = createSlice({
  name: 'comments',
  initialState: INITIAL_STATE,
  reducers: {
    setActiveCommentId(state, action: PayloadAction<number>) {
      state.activeCommentId = action.payload
    }
  }
})

export default commentSlice